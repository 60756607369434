// ON DOCUMENT READY
$(document).ready(() => {


	/*-------------------------------------------------*/
  /* =  POPUP
  /*-------------------------------------------------*/

  var popupTrigger = $('.popup-trigger');
  var popupClose = $('.close-popup, .popup__overlay');
  var popup = $('.popup');

  popupTrigger.on("click",  function() {
        var popupTrigger = $(this).data("popup");
        popup.removeClass("is-active");
        $('html,main, footer').addClass("is-active");
        $("#popup" + popupTrigger).addClass("is-active");
    }); 

    popupClose.on("click",  function() {
    popup.removeClass("is-active");
        $('html,main, footer').removeClass("is-active");
  }); 

  /*-------------------------------------------------*/
  /* =  Validation
  /*-------------------------------------------------*/

  $("#notify").validate({
    rules: {
        fullName: "required",
        email: {
            required: true,
            email: true,
        },
        pet: "required",
    },
    messages: {
        email: {
            required: "E-mail is required",
            email: "E-mail is not valid"
        },
    },
    submitHandler: function (form) {
      var data = $(form).serialize();
      $.ajax({
        type: "POST",
        url: 'assets/php/mail.php',
        data: data,
        success: function (data1) {
      		// console.log("proslo"); 
          popup.removeClass("is-active");
          $('#notify').trigger("reset");
          toastr.success("Thank you for your inquiry, we will notify you as soon as the service goes live.");
        },
        error: function (data2) {
          // console.log("nije proslo");  
          popup.removeClass("is-active");
          $('#notify').trigger("reset");
          toastr.error("Something wrong, try later.");

        },
      });
    }
  });
});
